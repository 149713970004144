<template>
  <v-row justify="center">
    <v-dialog v-model="dynamicDialogInformation" persistent :max-width="widthModal">
      <v-card :max-height="heightCard" class="overflow-y-auto pa-5">
        <v-card-title class="pa-5">
          <v-row justify="center">
            <v-col cols="12" style="color: #4d596a, font-weight: bold">
              <slot name="title" />
            </v-col>
            <v-col cols="12" style="color: #4d596a">
              <slot name="textInfo" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="12" xs="12" sm="12" md="3" lg="3">
              <v-btn
                color="#3B495B"
                outlined
                block
                @click="close()"
              >
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default ({
  name: 'DynamicDialogInformation',

  data: () => ({
    dynamicDialogInformation: false,
    widthModal: 700,
    heightCard: 480,
  }),

  props: { widthModalValue: Number },

  watch: {
    widthModalValue: {
      handler(val) {
        this.widthModal = val;
      },
      immediate: true,
    },
    heightCardValue: {
      handler(val) {
        this.heightCard = val;
      },
      immediate: true,
    },
  },

  methods: {
    open() {
      this.dynamicDialogInformation = true;
    },
    close() {
      this.dynamicDialogInformation = false;
    },
  },
});
</script>
